<template>
  <TapbarBase :show-submenu="showMenu" :submenu-arrow-pos="arrowLeftPos">
    <template #sub-menu>
      <div id="submenu-content" class="submenu-content" :class="{ 'sub-menu-overflowing': submenuOverFlowing }">
        <div class="flex gap-5 relative px-6 py-2 justify-start flex-nowrap h-full items-center text-gray-600">
          <ButtonSidebar
            class="rounded-md font-semibold text-lg"
            :active="view == 'teachers'"
            :full-width="false"
            :label="$t('teachers')"
            @click.native="openView('teachers')"
          >
            <template #svg>
              <faicon icon="cubes"></faicon>
            </template>
          </ButtonSidebar>
          <ButtonSidebar
            class="rounded-md font-semibold text-lg"
            :active="view == 'researchers'"
            :full-width="false"
            :label="$t('researchers')"
            @click.native="openView('researchers')"
          >
            <template #svg>
              <faicon icon="cubes"></faicon>
            </template>
          </ButtonSidebar>
          <ButtonSidebar
            class="rounded-md font-semibold text-lg"
            :active="view == 'researchers-dl57'"
            :full-width="false"
            :label="$t('researchers57')"
            @click.native="openView('researchers-dl57')"
          >
            <template #svg>
              <faicon icon="cubes"></faicon>
            </template>
          </ButtonSidebar>
          <ButtonSidebar
            class="rounded-md font-semibold text-lg"
            :active="view == 'scholarships'"
            :full-width="false"
            :label="$t('scholarships')"
            @click.native="openView('scholarships')"
          >
            <template #svg>
              <faicon icon="cubes"></faicon>
            </template>
          </ButtonSidebar>
          <ButtonSidebar
            class="rounded-md font-semibold text-lg"
            :active="view == 'staff'"
            :full-width="false"
            :label="$t('staff')"
            @click.native="openView('staff')"
          >
            <template #svg>
              <faicon icon="cubes"></faicon>
            </template>
          </ButtonSidebar>
          <ButtonSidebar
            class="rounded-md font-semibold text-lg"
            :active="view == 'leaders'"
            :full-width="false"
            :label="$t('administration')"
            @click.native="openView('leaders')"
          >
            <template #svg>
              <faicon icon="cubes"></faicon>
            </template>
          </ButtonSidebar>
        </div>
      </div>
    </template>
    <template #buttons>
      <ButtonTapbar to="/" :exact="true" :label="$t('home')">
        <template #svg>
          <fw-icon-home class="w-7 h-7" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar to="/applications" :label="$tc('applications')">
        <template #svg>
          <faicon icon="cube" size="lg" class="w-7 h-7"></faicon>
        </template>
      </ButtonTapbar>
      <ButtonTapbar ref="btn_explore" :label="$t('explore')" @click.native="openMenu('btn_explore')">
        <template #svg>
          <fw-icon-compass class="w-7 h-7" />
        </template>
      </ButtonTapbar>
      <ButtonTapbar to="/account" :label="$t('account')">
        <template #svg>
          <fw-icon-account-box class="w-7 h-7" />
        </template>
      </ButtonTapbar>
    </template>
  </TapbarBase>
</template>

<script>
import TapbarBase from '@/fw-modules/fw-core-vue/ui/components/tapbars/TapbarBase'
import ButtonTapbar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonTapbar'
import ButtonSidebar from '../../fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'

export default {
  components: {
    ButtonTapbar,
    TapbarBase,
    ButtonSidebar,
  },
  data() {
    return {
      activeSubmenu: null,
      showMenu: false,
      arrowLeftPos: 0,
      submenuOverFlowing: true,
      view: '', //TODO:
    }
  },
  mounted() {
    if (
      this.activeSubmenu !== 'procedureSteps' &&
      (this.view === 'metadata' || this.view === 'criteria' || this.view === 'jury')
    ) {
      this.$nextTick(() => {
        this.openMenu('procedureSteps', 'procedimentoBtn')
      })
    } else if (
      this.activeSubmenu !== 'steps' &&
      (this.view === 'admission' ||
        this.view === 'seriation-curriculum' ||
        this.view === 'seriation-interview' ||
        this.view === 'final')
    ) {
      this.$nextTick(() => {
        this.openMenu('steps', 'stepsBtn')
      })
    } else if (this.view === 'updates' || this.view === 'calendar' || this.view === 'complaints') {
      this.$nextTick(() => {
        this.openMenu('moreoptions', 'moreBtn')
      })
    }
  },
  methods: {
    openView(view, collapseMenu = false) {
      console.log('open view', view)
      if (collapseMenu) {
        this.showMenu = false
        this.activeSubmenu = ''
      }

      this.$emit('go-to-view', view)
    },
    openMenu(btnRef) {
      this.arrowLeftPos = this.$refs[btnRef].$el.getBoundingClientRect().left + this.$refs[btnRef].$el.offsetWidth / 2
      this.showMenu = !this.showMenu
    },
    scrollTo(posX) {
      let element = document.getElementById('submenu-content')

      element.scrollTo({
        top: 0,
        left: element.scrollLeft + posX,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style scope lang="postcss">
.submenu-content {
  @apply flex justify-center overflow-y-auto h-full items-center;
}
.submenu-content.sub-menu-overflowing {
  @apply justify-start;
}
</style>

<i18n>
{
  "pt": {
    "home": "Início",
    "applications": "Candidaturas",
    "explore": "Explorar",
    "teachers": "Pessoal docente",
    "staff": "Pessoal não docente",
    "researchers": "Investigadores",
    "researchers57": "Investigadores DL 57",
    "scholarships": "Bolsas",
    "administration": "Dirigentes",
    "account": "Conta"
  },
  "en": {
    "home": "Home",
    "applications": "Applications",
    "explore": "Explore",
    "teachers": "Teaching staff",
    "staff": "Non-teaching staff",
    "researchers": "Researchers",
    "researchers57": "Researchers (DL 57)",
    "scholarships": "Scholarships",
    "administration": "Administration",
    "account": "Account"
  }
}
</i18n>
